.searchCompareInput {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 220px;
  outline: none;
  border: none;
  box-shadow: none;
  background: #eee;
  margin-top: 4px; }

.hideSearch {
  display: none; }

.typeContainer {
  background: transparent; }

.resultsContainer {
  position: absolute;
  background: #fff; }
